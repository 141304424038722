<template>
    <main class="row pt-4 bg-primary text-light" id="projects">
        <h4 class="col-12">Showcased Projects</h4>
        <div
            class="col-12 project d-flex align-items-center flex-column flex-md-row mb-4 mb-md-0"
            v-for="project in projects"
            :key="project.name.replace(' ', '-')"
        >
            <div class="text-left m-1">
                <strong>{{ project.name }}</strong>
				<br />
				{{ project.desc }}
                <div class="mt-2">
                    <img
                        class="img-fluid project-tech m-1"
                        :src="tech.image"
                        :alt="tech.desc"
						:title="tech.desc"
                        v-for="tech in project.techs"
                        :key="`ptech-${tech.desc.replace(' ', '-')}`"
                    />
                </div>
            </div>
            <div class="m-2">
                <img
                    class="img-fluid img-thumbnail"
                    :alt="`Screenshot of ${project.name}`"
                    :title="`Screenshot of ${project.name}`"
                    :src="project.image"
                    v-if="project.image"
                />
            </div>
            <div class="text-left d-flex align-items-center justify-content-center m-2">
                <a class="mx-3" v-if="project.github" :href="project.github" target="_blank" rel="noopener noreferrer">
                    <img
						src="/images/projects/repo-white.png"
						class="img-fluid"
						alt="Link to project repository"
						title="Link to project repository"
					/>
                </a>
                <a class="mx-3" v-if="project.live" :href="project.live" target="_blank" rel="noopener noreferrer">
                    <img
						src="/images/projects/live-white.png"
						class="img-fluid"
						alt="Link to live project"
						title="Link to live project"
					/>
                </a>
            </div>
        </div>
        <div class="col text-right py-2">
            <button type="button" class="btn btn-link text-light" @click="scrollTop()">Back to top</button>
        </div>
    </main>
</template>

<script>
export default {
    props: ['scrollTop'],
    data: function() {
        return {
            projects: [
                {
                    name: 'Game Corner',
                    desc: 'A collection of classic games and activities developed from scratch.',
                    image: '/images/projects/game-corner.png',
                    live: 'https://gc.pranavchary.com',
                    techs: [
                        { desc: 'Angular', image: '/images/tech/angular.png' },
                        { desc: 'Bootstrap', image: '/images/tech/bootstrap.png' },
                    ]
                },
              {
                name: 'Gamon',
                desc: 'A bot application usable in Discord servers to give World of Warcraft players insights on how to increase their Mythic+ score for the current season',
                github: 'https://github.com/pranavchary/tld-gamon',
                image: '/images/projects/gamon.png',
                techs: [
                  { desc: 'Node.js', image: '/images/tech/node.png' }
                ]
              },
              {
                name: 'Shiny Stories',
                desc: 'A web application for players of Pokémon video games to use in order to track progress as they try to encounter special "shiny" Pokémon',
					      image: '/images/projects/shiny-stories.png',
					      live: 'https://shiny-stories.pranavchary.com',
                    techs: [
                        { desc: 'Vue', image: '/images/tech/vue.png' },
                        { desc: 'Bootstrap', image: '/images/tech/bootstrap.png' },
                        { desc: 'Firebase', image: '/images/tech/firebase.png' },
                    ]
                },
                {
                    name: 'Card Match',
                    desc: 'A project meant to simulate the popular memory-building game played by many people around the world.',
                    image: '/images/projects/card-match.png',
                    github: 'https://www.github.com/pranavchary/card-match',
                    live: 'https://pranavchary.github.io/card-match',
                    techs: [
                        { desc: 'React', image: '/images/tech/react.png' },
                        { desc: 'Bootstrap', image: '/images/tech/bootstrap.png' },
                    ]
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
	.project > div {
		flex: 1;
	}

	.project-tech {
		max-width: 2.5rem;
	}

	a > img {
		width: 3rem;
	}

	@media (prefers-color-scheme: dark) {
		html:not(.theme-light) {
			.img-thumbnail {
				opacity: 0.9;
			}
		}
	}
</style>