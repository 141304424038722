<template>
    <main class="row pt-4 bg-light" id="skillset">
        <h4 class="col-12 text-secondary">Technology I've worked with</h4>
        <div
            class="col-3 col-sm-2 col-lg-1 d-flex flex-column align-items-center"
            v-for="item in tech"
            :key="`tech${tech.indexOf(item)}`"
        >
            <img class="img-fluid" :src="item.path" :alt="item.desc" />
            <div class="tech-desc d-none d-lg-block" v-if="item.showText">{{ item.desc }}</div>
        </div>
        <h4 class="col-12 text-secondary mt-3 mt-md-0">Tools I've used in my work</h4>
        <div class="col-12 text-left">
            <ul>
                <li>Atlassian (JIRA, Confluence, Bitbucket)</li>
                <li>Version Control: Git, SVN, Team Foundation Server (TFS)</li>
                <li>Code Editors: IDEA IntelliJ, Visual Studio Code, Visual Studio, Atom</li>
                <li>Database Management: DBVisualizer, PGAdmin 4, SQL Server Managment Studio (SSMS), MySQL Workbench, Firebase</li>
                <li>Design: Lucid, Photoshop, Figma, Zeplin, Sketch</li>
                <li>Other: Trello, Miro</li>
            </ul>
        </div>
        <div class="col-12">
            For more details, please
            <a href="/Pranav Chary - Resume.pdf" target="_blank" rel="noopener noreferrer">view my resume</a>.
        </div>
        <div class="col text-right py-2">
            <button type="button" class="btn btn-link" @click="scrollTop()">Back to top</button>
        </div>
    </main>
</template>

<script>
export default {
    props: ['scrollTop'],
    data: function() {
        return {
            tech: [
                { desc: 'HTML, CSS, & JavaScript', path: '/images/tech/webdev.png', showText: true },
                { desc: 'Bootstrap', path: '/images/tech/bootstrap.png', showText: true },
                { desc: 'jQuery', path: '/images/tech/jquery.png', showText: false },
                { desc: 'Angular', path: '/images/tech/angular.png', showText: true },
                { desc: 'Vue.js', path: '/images/tech/vue.png', showText: true },
                { desc: 'React & React Native', path: '/images/tech/react.png', showText: true },
                { desc: 'Redux', path: '/images/tech/redux.png', showText: true },
                { desc: 'Java', path: '/images/tech/java.png', showText: false },
                { desc: 'Spring & Spring Boot', path: '/images/tech/spring.png', showText: true },
                { desc: 'Node.js', path: '/images/tech/node.png', showText: true },
                { desc: 'Express', path: '/images/tech/express.png', showText: false },
                { desc: 'C# (C Sharp)', path: '/images/tech/csharp.png', showText: false },
                { desc: '.NET Framework', path: '/images/tech/netmvc.png', showText: false },
                { desc: 'PostgreSQL', path: '/images/tech/postgres.png', showText: true },
                { desc: 'MySQL', path: '/images/tech/mysql.png', showText: false },
                { desc: 'SQL Server', path: '/images/tech/sqlserver.png', showText: false },
                { desc: 'Amazon Web Services', path: '/images/tech/aws.png', showText: false },
                { desc: 'Firebase', path: '/images/tech/firebase.png', showText: true }
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
	.tech-desc {
		font-size: 0.5rem;
	}

	@media (prefers-color-scheme: dark) {
		html:not(.theme-light) {
			img {
				background-color: #FAFAFA;
				border-radius: 25%;
				padding: 0.25rem;
				margin: 0.5rem 0;
			}
		}
	}
</style>