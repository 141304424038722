<template>
    <div class="container-fluid text-center">
		<div class="row pt-4 text-light bg-primary">
			<h1 class="col-12 mb-4">Pranav Chary</h1>
		</div>
        <div class="row pb-md-4 text-light bg-primary">
          <div
            class="col-12 col-md d-flex text-left pb-4 pb-md-0"
            v-for="info in points"
            :key="`intro-point-${points.indexOf(info)}`"
          >
            {{ info }}
          </div>
		</div>
		<About :scrollTop="scrollTop" />
		<Skillset :scrollTop="scrollTop" />
		<Projects :scrollTop="scrollTop" />
		<Connect :scrollTop="scrollTop" :darkModeEnabled="darkModeEnabled" />
    </div>
</template>

<script>
	import About from '../components/About';
	import Skillset from '../components/Skillset';
	import Projects from '../components/Projects';
	import Connect from '../components/Connect';
  export default {
	components: {
		About,
		Skillset,
		Projects,
		Connect,
    },
	props: {
		darkModeEnabled: {
			type: Boolean,
			default: true,
		},
	},
    data: function() {
      return {
        points: [
			'Highly skilled in web development with HTML/CSS using libraries such as Bootstrap, jQuery, and React (with Redux) as well as frameworks like .NET MVC and Vue.js',
			'Proficient in back-end development using Node.js (with Express) as well as C# with databases such as MySQL, SQLServer, MongoDB, and Firebase',
			'Experienced with mobile application development using React Native',
        ]
      }
    },
    methods: {
      scrollTop: function () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }
</script>