<template>
	<div class="row pt-4 bg-light" id="about">
		<h2 class="col-12">
			full stack developer
			<br class="d-lg-none" />
			<span class="d-none d-lg-inline">&nbsp;•&nbsp;</span>artist
			<br class="d-lg-none" />
			<span class="d-none d-lg-inline">&nbsp;•&nbsp;</span>musician
			<br class="d-lg-none" />
			<span class="d-none d-lg-inline">&nbsp;•&nbsp;</span>sports fan
		</h2>
	</div>
	<div class="row bg-light">
		<div class="col-12 text-primary">
			My goal is to develop aesthetically pleasing, user-friendly, and responsive websites/applications for both web and mobile platforms.
		</div>
	</div>
    <main class="row pt-4 flex-column-reverse flex-md-row bg-light">
        <div class="col-12 text-right d-md-none py-2">
            <button type="button" class="btn btn-link" @click="scrollTop()">Back to top</button>
        </div>
        <div class="col-12 col-md-4">
            <div>
                <blockquote
                    class="blockquote p-3 p-md-2 mb-4 mb-md-3 border-secondary"
                    v-for="quote in quotes"
                    :key="`quote-${quotes.indexOf(quote)}`"
                >
                    <p class="text-left text-secondary">{{ quote.text }}</p>
                    <footer class="blockquote-footer text-right">
						<cite title="Quote source">{{ quote.source }}</cite>
					</footer>
                </blockquote>
            </div>
        </div>
        <div class="col-12 col-md-8 text-left">
            <p>
                A passionate developer who is full of creative ideas, I enjoy working in different areas of digital experience while absorbing knowledge like a sponge along the way. I have been working as a web developer for {{ yearsWorked }} years.
            </p>
            <p>
                I have always had an interest in computers, and enjoyed learning about how programming and code could be used. Using basic HTML and CSS to style pages on different social media websites, I found that I truly enjoyed setting my pages apart with unique (sometimes strange) designs and color schemes.
            </p>
            <p>
                During college, I learned more about programming fundamentals and how to create functional web pages using HTML and CSS. Shortly after graduation, I was introduced to the software development industry and learned about I could turn a hobby into a viable career option.
            </p>
            <p>
                I am currently based in the Dallas-Forth Worth (DFW) metroplex. Aside from software development, I enjoy listening to and creating music, watching all kinds of sports, and creating art with different mediums.
            </p>
            <p class="extra-info d-none d-md-block">
                Some fun facts about me:
                <ul>
                    <li>I almost completed a music minor in college playing the trombone.</li>
                    <li>
                        Though I enjoy watching all sports, my favorite is American Football. I love to participate in fantasy football leagues!
                    </li>
                    <li>
                        I have a page on Instagram where I post some of the art that I work on. You can find a link to it in the Connect section.
                    </li>
                    <li>
                        I don't play many video games, but my favorite franchises include Warcraft, Pokémon, God of War, & Call of Duty.
                    </li>
                </ul>
            </p>
        </div>
    </main>
</template>

<script>
export default {
    props: ['scrollTop'],
    data: function() {
        return {
            quotes: [
                {
                    text: '"Creativity is putting your imagination to work, and it\'s produced the most extraordinary results in human culture."',
                    source: 'Sir Ken Robinson'
                },
                {
                    text: '"You can\'t use up creativity. The more you use, the more you have."',
                    source: 'Maya Angelou'
                },
                {
                    text: '"Creativity is intelligence having fun."',
                    source: 'Albert Einstein'
                }
            ],
            yearsWorked: new Date().getFullYear() - 2016
        }
    }
}
</script>

<style lang="scss" scoped>
	blockquote {
		border: 1px solid red;
		border-radius: 1rem;
	}

    .img-fluid {
		border-radius: 1rem;
    }
</style>