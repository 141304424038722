<template>
    <main class="row pt-4 bg-light" id="connect">
		<h4 class="col-12">Connect with me</h4>
        <div class="col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
            <a
				class="d-inline-block mx-3 mb-3"
				href="https://www.linkedin.com/in/pranavchary"
				target="_blank"
				rel="noopener noreferrer"
			>
                <img :src="`/images/linkedin${darkModeEnabled ? '-dark' : ''}.png`" alt="LinkedIn" class="img-fluid" />
            </a>
			<a
				class="d-inline-block mx-3 mb-3"
				href="https://www.instagram.com/pranav_draws"
				target="_blank"
				rel="noopener noreferrer"
			>
                <img :src="`/images/instagram${darkModeEnabled ? '-dark' : ''}.png`" alt="Instagram" class="img-fluid" />
            </a>
			<a
				class="d-inline-block mx-3 mb-3"
				href="mailto:pranav@pranavchary.com"
				target="_blank"
				rel="noopener noreferrer"
			>
                <img :src="`/images/email${darkModeEnabled ? '-dark' : ''}.png`" alt="Email" class="img-fluid" />
            </a>
        </div>
    </main>
</template>

<script>
export default {
	props: {
		darkModeEnabled: {
			type: Boolean,
			default: true,
		},
	},
}
</script>

<style lang="scss" scoped>
    img {
        width: 3rem;
        transform: scale(1);
        transition: transform 0.5s ease;

        &:hover {
            transform: scale(1.05);
            transition: transform 0.5s ease;
        }
    }
</style>